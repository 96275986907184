<template>
<div>
    <!-- <v-col> -->
    <v-app-bar v-if="!$route.meta.hideNavigation" :key="$route.path" :color="$vuetify.theme.dark ? '#1E1E1E' : 'white'" outlined flat height="70%">
        <v-btn @click="drawer = !drawer" icon="" depressed="" style="border-radius: 15px" fab v-if="!$route.meta.hideNavigation || !$route.meta.hideDrawer">
                <v-icon large="">{{drawer == false ? 'menu' : 'notes'}}</v-icon>
            </v-btn>

        <v-row align="center">
                <v-col cols="1" v-if="!isMobile && !drawer">
                    <v-container>
                        <v-avatar tile size='200'>
                            <v-img contain src="@/assets/images/logo.png"></v-img>
                        </v-avatar>
                    </v-container>
                </v-col>
                <div class="mx-2"/>
            </v-row>
        <v-spacer class="hidden-sm-and-down" />

        <h4>{{ $route.name }}</h4>

        <h3 v-if="isMobile">{{ $route.name }}</h3>
        <v-spacer />

        <v-spacer class="hidden-sm-and-down" />
        <!-- <notifications/> -->
        <themChanger />
        <fullscreen />
        <v-divider vertical="" inset style="border: solid 1px #D1DDE7" />
        <profile class="hidden-sm-and-down" />
        <v-divider vertical="" inset style="border: solid 1px #D1DDE7" />
        <v-btn icon @click="goBack()">
            <v-icon>
                fi fi-rr-angle-small-left
            </v-icon>
        </v-btn>

    </v-app-bar>
    <v-divider style="border: solid 1px #D1DDE7" />

    <v-app-bar outlined flat :color="$vuetify.theme.dark ? '#1E1E1E' : 'white'" v-if="!isMobile && !drawer">
        <v-row justify="center">
            <tabs />
        </v-row>
    </v-app-bar>
    <v-divider style="border: solid 1px #D1DDE7" />

    <!-- </v-col> -->
    <!-- <div v-intersect="onIntersect" /> -->
    <drawer :drawer="drawer" v-if="!$route.meta.hideNavigation || !$route.meta.hideDrawer" />
</div>
</template>

<script>
export default {
    components: {
        drawer: () => import('./Drawer.vue'),
        themChanger: () => import('./themeChanger'),
        // notifications: () => import('./notifications'),
        fullscreen: () => import('./Fullscreen'),
        profile: () => import('./Profile'),
        tabs: () => import('./tabs')
    },
    data() {
        return {
            username: '',
            drawer: false,
            isIntersecting: false,
            isMobile: false, 
            currentBalance: [{
                id: "3eb1ccc4-3b88-4119-8c06-c5db13fabcc6",
                totalBalance: 0,
                walletName: "",
            }]
        }
    },

    created() {
        this.onResize();
        window.addEventListener("resize", this.onResize, {
            passive: true
        });
        setTimeout(() => {
            this.$eventBus.$on(`refresh-balance`, () => {
                this.getMyBalance()
            })
        }, 100);
    },

    watch: {
        beforeDestroy() {
            if (typeof window !== "undefined") {
                window.removeEventListener("resize", this.onResize, {
                    passive: true
                });
            }
        }
    },

    methods: {
        openAddWithdrawDialog() {
            var item = {
                dialogType: 'add',
                title: 'سحب'
            }
            this.$store.commit('setItemDetails', item)
            this.$store.commit('setDialog3')
        },
        
        openAddDepositDialog() {
            var item = {
                dialogType: 'add',
                title: 'ايداع'
            }
            this.$store.commit('setItemDetails', item)
            this.$store.commit('setDialog4')
        },

        async getMyBalance() {
            let res = await this.$http.get(`Transaction/MyBalance`)
            console.log('res.data')
            console.log(res)
            this.currentBalance = res.data.result
            this.$store.state.balance = res.data.result[0].totalBalance
        },
        goBack() {
            window.history.go(-1)
        },
        onIntersect(entries, ) {
            this.isIntersecting = entries[0].isIntersecting
        },
        onResize() {
            this.isMobile = window.innerWidth < 1000;
        },
        getLength(x) {
            if (x != null) {
                if (x.length > 1) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        },

        routeTo(route) {
            this.$route.replace(route)
        }
    }
}
</script>
